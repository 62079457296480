import { defineComponent as Ur, openBlock as Dr, createElementBlock as Nr, computed as Xt, getCurrentInstance as Vt, onMounted as Mr, nextTick as qr, onUnmounted as Br, unref as Fr, ref as Hr, reactive as Gr, watch as Kr } from "vue";
import { usePage as ie, router as zr } from "@inertiajs/vue3";
const Vu = /* @__PURE__ */ Ur({
  __name: "Test",
  setup(e) {
    return console.log("Testing"), (t, r) => (Dr(), Nr("div", null, "Testing"));
  }
});
const Yu = () => Xt(() => {
  var r;
  const e = (r = ie().props) == null ? void 0 : r.modules;
  e.acf || console.warn("Germinal: ACF doesn't appear to be activated on this Wordpress installation");
  const t = (e == null ? void 0 : e.acf) || {};
  return Object.entries(t).reduce((n, [a, s]) => (s.value !== void 0 && (n[a] = s.value), n), {});
});
function Jr(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var He = { exports: {} }, Yt = function(t, r) {
  return function() {
    for (var a = new Array(arguments.length), s = 0; s < a.length; s++)
      a[s] = arguments[s];
    return t.apply(r, a);
  };
}, Wr = Yt, M = Object.prototype.toString;
function Ge(e) {
  return M.call(e) === "[object Array]";
}
function Ue(e) {
  return typeof e > "u";
}
function Xr(e) {
  return e !== null && !Ue(e) && e.constructor !== null && !Ue(e.constructor) && typeof e.constructor.isBuffer == "function" && e.constructor.isBuffer(e);
}
function Vr(e) {
  return M.call(e) === "[object ArrayBuffer]";
}
function Yr(e) {
  return typeof FormData < "u" && e instanceof FormData;
}
function kr(e) {
  var t;
  return typeof ArrayBuffer < "u" && ArrayBuffer.isView ? t = ArrayBuffer.isView(e) : t = e && e.buffer && e.buffer instanceof ArrayBuffer, t;
}
function Zr(e) {
  return typeof e == "string";
}
function Qr(e) {
  return typeof e == "number";
}
function kt(e) {
  return e !== null && typeof e == "object";
}
function te(e) {
  if (M.call(e) !== "[object Object]")
    return !1;
  var t = Object.getPrototypeOf(e);
  return t === null || t === Object.prototype;
}
function en(e) {
  return M.call(e) === "[object Date]";
}
function tn(e) {
  return M.call(e) === "[object File]";
}
function rn(e) {
  return M.call(e) === "[object Blob]";
}
function Zt(e) {
  return M.call(e) === "[object Function]";
}
function nn(e) {
  return kt(e) && Zt(e.pipe);
}
function an(e) {
  return typeof URLSearchParams < "u" && e instanceof URLSearchParams;
}
function sn(e) {
  return e.trim ? e.trim() : e.replace(/^\s+|\s+$/g, "");
}
function on() {
  return typeof navigator < "u" && (navigator.product === "ReactNative" || navigator.product === "NativeScript" || navigator.product === "NS") ? !1 : typeof window < "u" && typeof document < "u";
}
function Ke(e, t) {
  if (!(e === null || typeof e > "u"))
    if (typeof e != "object" && (e = [e]), Ge(e))
      for (var r = 0, n = e.length; r < n; r++)
        t.call(null, e[r], r, e);
    else
      for (var a in e)
        Object.prototype.hasOwnProperty.call(e, a) && t.call(null, e[a], a, e);
}
function De() {
  var e = {};
  function t(a, s) {
    te(e[s]) && te(a) ? e[s] = De(e[s], a) : te(a) ? e[s] = De({}, a) : Ge(a) ? e[s] = a.slice() : e[s] = a;
  }
  for (var r = 0, n = arguments.length; r < n; r++)
    Ke(arguments[r], t);
  return e;
}
function un(e, t, r) {
  return Ke(t, function(a, s) {
    r && typeof a == "function" ? e[s] = Wr(a, r) : e[s] = a;
  }), e;
}
function cn(e) {
  return e.charCodeAt(0) === 65279 && (e = e.slice(1)), e;
}
var j = {
  isArray: Ge,
  isArrayBuffer: Vr,
  isBuffer: Xr,
  isFormData: Yr,
  isArrayBufferView: kr,
  isString: Zr,
  isNumber: Qr,
  isObject: kt,
  isPlainObject: te,
  isUndefined: Ue,
  isDate: en,
  isFile: tn,
  isBlob: rn,
  isFunction: Zt,
  isStream: nn,
  isURLSearchParams: an,
  isStandardBrowserEnv: on,
  forEach: Ke,
  merge: De,
  extend: un,
  trim: sn,
  stripBOM: cn
}, H = j;
function et(e) {
  return encodeURIComponent(e).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}
var Qt = function(t, r, n) {
  if (!r)
    return t;
  var a;
  if (n)
    a = n(r);
  else if (H.isURLSearchParams(r))
    a = r.toString();
  else {
    var s = [];
    H.forEach(r, function(f, u) {
      f === null || typeof f > "u" || (H.isArray(f) ? u = u + "[]" : f = [f], H.forEach(f, function(h) {
        H.isDate(h) ? h = h.toISOString() : H.isObject(h) && (h = JSON.stringify(h)), s.push(et(u) + "=" + et(h));
      }));
    }), a = s.join("&");
  }
  if (a) {
    var o = t.indexOf("#");
    o !== -1 && (t = t.slice(0, o)), t += (t.indexOf("?") === -1 ? "?" : "&") + a;
  }
  return t;
}, fn = j;
function ue() {
  this.handlers = [];
}
ue.prototype.use = function(t, r, n) {
  return this.handlers.push({
    fulfilled: t,
    rejected: r,
    synchronous: n ? n.synchronous : !1,
    runWhen: n ? n.runWhen : null
  }), this.handlers.length - 1;
};
ue.prototype.eject = function(t) {
  this.handlers[t] && (this.handlers[t] = null);
};
ue.prototype.forEach = function(t) {
  fn.forEach(this.handlers, function(n) {
    n !== null && t(n);
  });
};
var ln = ue, dn = j, pn = function(t, r) {
  dn.forEach(t, function(a, s) {
    s !== r && s.toUpperCase() === r.toUpperCase() && (t[r] = a, delete t[s]);
  });
}, er = function(t, r, n, a, s) {
  return t.config = r, n && (t.code = n), t.request = a, t.response = s, t.isAxiosError = !0, t.toJSON = function() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this.code
    };
  }, t;
}, he, tt;
function tr() {
  if (tt)
    return he;
  tt = 1;
  var e = er;
  return he = function(r, n, a, s, o) {
    var c = new Error(r);
    return e(c, n, a, s, o);
  }, he;
}
var ge, rt;
function hn() {
  if (rt)
    return ge;
  rt = 1;
  var e = tr();
  return ge = function(r, n, a) {
    var s = a.config.validateStatus;
    !a.status || !s || s(a.status) ? r(a) : n(e(
      "Request failed with status code " + a.status,
      a.config,
      null,
      a.request,
      a
    ));
  }, ge;
}
var me, nt;
function gn() {
  if (nt)
    return me;
  nt = 1;
  var e = j;
  return me = e.isStandardBrowserEnv() ? (
    // Standard browser envs support document.cookie
    function() {
      return {
        write: function(n, a, s, o, c, f) {
          var u = [];
          u.push(n + "=" + encodeURIComponent(a)), e.isNumber(s) && u.push("expires=" + new Date(s).toGMTString()), e.isString(o) && u.push("path=" + o), e.isString(c) && u.push("domain=" + c), f === !0 && u.push("secure"), document.cookie = u.join("; ");
        },
        read: function(n) {
          var a = document.cookie.match(new RegExp("(^|;\\s*)(" + n + ")=([^;]*)"));
          return a ? decodeURIComponent(a[3]) : null;
        },
        remove: function(n) {
          this.write(n, "", Date.now() - 864e5);
        }
      };
    }()
  ) : (
    // Non standard browser env (web workers, react-native) lack needed support.
    function() {
      return {
        write: function() {
        },
        read: function() {
          return null;
        },
        remove: function() {
        }
      };
    }()
  ), me;
}
var ve, at;
function mn() {
  return at || (at = 1, ve = function(t) {
    return /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(t);
  }), ve;
}
var ye, st;
function vn() {
  return st || (st = 1, ye = function(t, r) {
    return r ? t.replace(/\/+$/, "") + "/" + r.replace(/^\/+/, "") : t;
  }), ye;
}
var be, ot;
function yn() {
  if (ot)
    return be;
  ot = 1;
  var e = mn(), t = vn();
  return be = function(n, a) {
    return n && !e(a) ? t(n, a) : a;
  }, be;
}
var we, it;
function bn() {
  if (it)
    return we;
  it = 1;
  var e = j, t = [
    "age",
    "authorization",
    "content-length",
    "content-type",
    "etag",
    "expires",
    "from",
    "host",
    "if-modified-since",
    "if-unmodified-since",
    "last-modified",
    "location",
    "max-forwards",
    "proxy-authorization",
    "referer",
    "retry-after",
    "user-agent"
  ];
  return we = function(n) {
    var a = {}, s, o, c;
    return n && e.forEach(n.split(`
`), function(u) {
      if (c = u.indexOf(":"), s = e.trim(u.substr(0, c)).toLowerCase(), o = e.trim(u.substr(c + 1)), s) {
        if (a[s] && t.indexOf(s) >= 0)
          return;
        s === "set-cookie" ? a[s] = (a[s] ? a[s] : []).concat([o]) : a[s] = a[s] ? a[s] + ", " + o : o;
      }
    }), a;
  }, we;
}
var _e, ut;
function wn() {
  if (ut)
    return _e;
  ut = 1;
  var e = j;
  return _e = e.isStandardBrowserEnv() ? (
    // Standard browser envs have full support of the APIs needed to test
    // whether the request URL is of the same origin as current location.
    function() {
      var r = /(msie|trident)/i.test(navigator.userAgent), n = document.createElement("a"), a;
      function s(o) {
        var c = o;
        return r && (n.setAttribute("href", c), c = n.href), n.setAttribute("href", c), {
          href: n.href,
          protocol: n.protocol ? n.protocol.replace(/:$/, "") : "",
          host: n.host,
          search: n.search ? n.search.replace(/^\?/, "") : "",
          hash: n.hash ? n.hash.replace(/^#/, "") : "",
          hostname: n.hostname,
          port: n.port,
          pathname: n.pathname.charAt(0) === "/" ? n.pathname : "/" + n.pathname
        };
      }
      return a = s(window.location.href), function(c) {
        var f = e.isString(c) ? s(c) : c;
        return f.protocol === a.protocol && f.host === a.host;
      };
    }()
  ) : (
    // Non standard browser envs (web workers, react-native) lack needed support.
    function() {
      return function() {
        return !0;
      };
    }()
  ), _e;
}
var Te, ct;
function ft() {
  if (ct)
    return Te;
  ct = 1;
  var e = j, t = hn(), r = gn(), n = Qt, a = yn(), s = bn(), o = wn(), c = tr();
  return Te = function(u) {
    return new Promise(function(h, p) {
      var d = u.data, g = u.headers, l = u.responseType;
      e.isFormData(d) && delete g["Content-Type"];
      var i = new XMLHttpRequest();
      if (u.auth) {
        var m = u.auth.username || "", T = u.auth.password ? unescape(encodeURIComponent(u.auth.password)) : "";
        g.Authorization = "Basic " + btoa(m + ":" + T);
      }
      var x = a(u.baseURL, u.url);
      i.open(u.method.toUpperCase(), n(x, u.params, u.paramsSerializer), !0), i.timeout = u.timeout;
      function O() {
        if (i) {
          var S = "getAllResponseHeaders" in i ? s(i.getAllResponseHeaders()) : null, v = !l || l === "text" || l === "json" ? i.responseText : i.response, $ = {
            data: v,
            status: i.status,
            statusText: i.statusText,
            headers: S,
            config: u,
            request: i
          };
          t(h, p, $), i = null;
        }
      }
      if ("onloadend" in i ? i.onloadend = O : i.onreadystatechange = function() {
        !i || i.readyState !== 4 || i.status === 0 && !(i.responseURL && i.responseURL.indexOf("file:") === 0) || setTimeout(O);
      }, i.onabort = function() {
        i && (p(c("Request aborted", u, "ECONNABORTED", i)), i = null);
      }, i.onerror = function() {
        p(c("Network Error", u, null, i)), i = null;
      }, i.ontimeout = function() {
        var v = "timeout of " + u.timeout + "ms exceeded";
        u.timeoutErrorMessage && (v = u.timeoutErrorMessage), p(c(
          v,
          u,
          u.transitional && u.transitional.clarifyTimeoutError ? "ETIMEDOUT" : "ECONNABORTED",
          i
        )), i = null;
      }, e.isStandardBrowserEnv()) {
        var _ = (u.withCredentials || o(x)) && u.xsrfCookieName ? r.read(u.xsrfCookieName) : void 0;
        _ && (g[u.xsrfHeaderName] = _);
      }
      "setRequestHeader" in i && e.forEach(g, function(v, $) {
        typeof d > "u" && $.toLowerCase() === "content-type" ? delete g[$] : i.setRequestHeader($, v);
      }), e.isUndefined(u.withCredentials) || (i.withCredentials = !!u.withCredentials), l && l !== "json" && (i.responseType = u.responseType), typeof u.onDownloadProgress == "function" && i.addEventListener("progress", u.onDownloadProgress), typeof u.onUploadProgress == "function" && i.upload && i.upload.addEventListener("progress", u.onUploadProgress), u.cancelToken && u.cancelToken.promise.then(function(v) {
        i && (i.abort(), p(v), i = null);
      }), d || (d = null), i.send(d);
    });
  }, Te;
}
var A = j, lt = pn, _n = er, Tn = {
  "Content-Type": "application/x-www-form-urlencoded"
};
function dt(e, t) {
  !A.isUndefined(e) && A.isUndefined(e["Content-Type"]) && (e["Content-Type"] = t);
}
function $n() {
  var e;
  return (typeof XMLHttpRequest < "u" || typeof process < "u" && Object.prototype.toString.call(process) === "[object process]") && (e = ft()), e;
}
function An(e, t, r) {
  if (A.isString(e))
    try {
      return (t || JSON.parse)(e), A.trim(e);
    } catch (n) {
      if (n.name !== "SyntaxError")
        throw n;
    }
  return (r || JSON.stringify)(e);
}
var ce = {
  transitional: {
    silentJSONParsing: !0,
    forcedJSONParsing: !0,
    clarifyTimeoutError: !1
  },
  adapter: $n(),
  transformRequest: [function(t, r) {
    return lt(r, "Accept"), lt(r, "Content-Type"), A.isFormData(t) || A.isArrayBuffer(t) || A.isBuffer(t) || A.isStream(t) || A.isFile(t) || A.isBlob(t) ? t : A.isArrayBufferView(t) ? t.buffer : A.isURLSearchParams(t) ? (dt(r, "application/x-www-form-urlencoded;charset=utf-8"), t.toString()) : A.isObject(t) || r && r["Content-Type"] === "application/json" ? (dt(r, "application/json"), An(t)) : t;
  }],
  transformResponse: [function(t) {
    var r = this.transitional, n = r && r.silentJSONParsing, a = r && r.forcedJSONParsing, s = !n && this.responseType === "json";
    if (s || a && A.isString(t) && t.length)
      try {
        return JSON.parse(t);
      } catch (o) {
        if (s)
          throw o.name === "SyntaxError" ? _n(o, this, "E_JSON_PARSE") : o;
      }
    return t;
  }],
  /**
   * A timeout in milliseconds to abort a request. If set to 0 (default) a
   * timeout is not created.
   */
  timeout: 0,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  maxContentLength: -1,
  maxBodyLength: -1,
  validateStatus: function(t) {
    return t >= 200 && t < 300;
  }
};
ce.headers = {
  common: {
    Accept: "application/json, text/plain, */*"
  }
};
A.forEach(["delete", "get", "head"], function(t) {
  ce.headers[t] = {};
});
A.forEach(["post", "put", "patch"], function(t) {
  ce.headers[t] = A.merge(Tn);
});
var ze = ce, En = j, On = ze, Sn = function(t, r, n) {
  var a = this || On;
  return En.forEach(n, function(o) {
    t = o.call(a, t, r);
  }), t;
}, $e, pt;
function rr() {
  return pt || (pt = 1, $e = function(t) {
    return !!(t && t.__CANCEL__);
  }), $e;
}
var ht = j, Ae = Sn, jn = rr(), xn = ze;
function Ee(e) {
  e.cancelToken && e.cancelToken.throwIfRequested();
}
var Pn = function(t) {
  Ee(t), t.headers = t.headers || {}, t.data = Ae.call(
    t,
    t.data,
    t.headers,
    t.transformRequest
  ), t.headers = ht.merge(
    t.headers.common || {},
    t.headers[t.method] || {},
    t.headers
  ), ht.forEach(
    ["delete", "get", "head", "post", "put", "patch", "common"],
    function(a) {
      delete t.headers[a];
    }
  );
  var r = t.adapter || xn.adapter;
  return r(t).then(function(a) {
    return Ee(t), a.data = Ae.call(
      t,
      a.data,
      a.headers,
      t.transformResponse
    ), a;
  }, function(a) {
    return jn(a) || (Ee(t), a && a.response && (a.response.data = Ae.call(
      t,
      a.response.data,
      a.response.headers,
      t.transformResponse
    ))), Promise.reject(a);
  });
}, E = j, nr = function(t, r) {
  r = r || {};
  var n = {}, a = ["url", "method", "data"], s = ["headers", "auth", "proxy", "params"], o = [
    "baseURL",
    "transformRequest",
    "transformResponse",
    "paramsSerializer",
    "timeout",
    "timeoutMessage",
    "withCredentials",
    "adapter",
    "responseType",
    "xsrfCookieName",
    "xsrfHeaderName",
    "onUploadProgress",
    "onDownloadProgress",
    "decompress",
    "maxContentLength",
    "maxBodyLength",
    "maxRedirects",
    "transport",
    "httpAgent",
    "httpsAgent",
    "cancelToken",
    "socketPath",
    "responseEncoding"
  ], c = ["validateStatus"];
  function f(p, d) {
    return E.isPlainObject(p) && E.isPlainObject(d) ? E.merge(p, d) : E.isPlainObject(d) ? E.merge({}, d) : E.isArray(d) ? d.slice() : d;
  }
  function u(p) {
    E.isUndefined(r[p]) ? E.isUndefined(t[p]) || (n[p] = f(void 0, t[p])) : n[p] = f(t[p], r[p]);
  }
  E.forEach(a, function(d) {
    E.isUndefined(r[d]) || (n[d] = f(void 0, r[d]));
  }), E.forEach(s, u), E.forEach(o, function(d) {
    E.isUndefined(r[d]) ? E.isUndefined(t[d]) || (n[d] = f(void 0, t[d])) : n[d] = f(void 0, r[d]);
  }), E.forEach(c, function(d) {
    d in r ? n[d] = f(t[d], r[d]) : d in t && (n[d] = f(void 0, t[d]));
  });
  var y = a.concat(s).concat(o).concat(c), h = Object.keys(t).concat(Object.keys(r)).filter(function(d) {
    return y.indexOf(d) === -1;
  });
  return E.forEach(h, u), n;
};
const Cn = "axios", Rn = "0.21.4", Ln = "Promise based HTTP client for the browser and node.js", In = "index.js", Un = {
  test: "grunt test",
  start: "node ./sandbox/server.js",
  build: "NODE_ENV=production grunt build",
  preversion: "npm test",
  version: "npm run build && grunt version && git add -A dist && git add CHANGELOG.md bower.json package.json",
  postversion: "git push && git push --tags",
  examples: "node ./examples/server.js",
  coveralls: "cat coverage/lcov.info | ./node_modules/coveralls/bin/coveralls.js",
  fix: "eslint --fix lib/**/*.js"
}, Dn = {
  type: "git",
  url: "https://github.com/axios/axios.git"
}, Nn = [
  "xhr",
  "http",
  "ajax",
  "promise",
  "node"
], Mn = "Matt Zabriskie", qn = "MIT", Bn = {
  url: "https://github.com/axios/axios/issues"
}, Fn = "https://axios-http.com", Hn = {
  coveralls: "^3.0.0",
  "es6-promise": "^4.2.4",
  grunt: "^1.3.0",
  "grunt-banner": "^0.6.0",
  "grunt-cli": "^1.2.0",
  "grunt-contrib-clean": "^1.1.0",
  "grunt-contrib-watch": "^1.0.0",
  "grunt-eslint": "^23.0.0",
  "grunt-karma": "^4.0.0",
  "grunt-mocha-test": "^0.13.3",
  "grunt-ts": "^6.0.0-beta.19",
  "grunt-webpack": "^4.0.2",
  "istanbul-instrumenter-loader": "^1.0.0",
  "jasmine-core": "^2.4.1",
  karma: "^6.3.2",
  "karma-chrome-launcher": "^3.1.0",
  "karma-firefox-launcher": "^2.1.0",
  "karma-jasmine": "^1.1.1",
  "karma-jasmine-ajax": "^0.1.13",
  "karma-safari-launcher": "^1.0.0",
  "karma-sauce-launcher": "^4.3.6",
  "karma-sinon": "^1.0.5",
  "karma-sourcemap-loader": "^0.3.8",
  "karma-webpack": "^4.0.2",
  "load-grunt-tasks": "^3.5.2",
  minimist: "^1.2.0",
  mocha: "^8.2.1",
  sinon: "^4.5.0",
  "terser-webpack-plugin": "^4.2.3",
  typescript: "^4.0.5",
  "url-search-params": "^0.10.0",
  webpack: "^4.44.2",
  "webpack-dev-server": "^3.11.0"
}, Gn = {
  "./lib/adapters/http.js": "./lib/adapters/xhr.js"
}, Kn = "dist/axios.min.js", zn = "dist/axios.min.js", Jn = "./index.d.ts", Wn = {
  "follow-redirects": "^1.14.0"
}, Xn = [
  {
    path: "./dist/axios.min.js",
    threshold: "5kB"
  }
], Vn = {
  name: Cn,
  version: Rn,
  description: Ln,
  main: In,
  scripts: Un,
  repository: Dn,
  keywords: Nn,
  author: Mn,
  license: qn,
  bugs: Bn,
  homepage: Fn,
  devDependencies: Hn,
  browser: Gn,
  jsdelivr: Kn,
  unpkg: zn,
  typings: Jn,
  dependencies: Wn,
  bundlesize: Xn
};
var ar = Vn, Je = {};
["object", "boolean", "number", "function", "string", "symbol"].forEach(function(e, t) {
  Je[e] = function(n) {
    return typeof n === e || "a" + (t < 1 ? "n " : " ") + e;
  };
});
var gt = {}, Yn = ar.version.split(".");
function sr(e, t) {
  for (var r = t ? t.split(".") : Yn, n = e.split("."), a = 0; a < 3; a++) {
    if (r[a] > n[a])
      return !0;
    if (r[a] < n[a])
      return !1;
  }
  return !1;
}
Je.transitional = function(t, r, n) {
  var a = r && sr(r);
  function s(o, c) {
    return "[Axios v" + ar.version + "] Transitional option '" + o + "'" + c + (n ? ". " + n : "");
  }
  return function(o, c, f) {
    if (t === !1)
      throw new Error(s(c, " has been removed in " + r));
    return a && !gt[c] && (gt[c] = !0, console.warn(
      s(
        c,
        " has been deprecated since v" + r + " and will be removed in the near future"
      )
    )), t ? t(o, c, f) : !0;
  };
};
function kn(e, t, r) {
  if (typeof e != "object")
    throw new TypeError("options must be an object");
  for (var n = Object.keys(e), a = n.length; a-- > 0; ) {
    var s = n[a], o = t[s];
    if (o) {
      var c = e[s], f = c === void 0 || o(c, s, e);
      if (f !== !0)
        throw new TypeError("option " + s + " must be " + f);
      continue;
    }
    if (r !== !0)
      throw Error("Unknown option " + s);
  }
}
var Zn = {
  isOlderVersion: sr,
  assertOptions: kn,
  validators: Je
}, or = j, Qn = Qt, mt = ln, vt = Pn, fe = nr, ir = Zn, G = ir.validators;
function k(e) {
  this.defaults = e, this.interceptors = {
    request: new mt(),
    response: new mt()
  };
}
k.prototype.request = function(t) {
  typeof t == "string" ? (t = arguments[1] || {}, t.url = arguments[0]) : t = t || {}, t = fe(this.defaults, t), t.method ? t.method = t.method.toLowerCase() : this.defaults.method ? t.method = this.defaults.method.toLowerCase() : t.method = "get";
  var r = t.transitional;
  r !== void 0 && ir.assertOptions(r, {
    silentJSONParsing: G.transitional(G.boolean, "1.0.0"),
    forcedJSONParsing: G.transitional(G.boolean, "1.0.0"),
    clarifyTimeoutError: G.transitional(G.boolean, "1.0.0")
  }, !1);
  var n = [], a = !0;
  this.interceptors.request.forEach(function(p) {
    typeof p.runWhen == "function" && p.runWhen(t) === !1 || (a = a && p.synchronous, n.unshift(p.fulfilled, p.rejected));
  });
  var s = [];
  this.interceptors.response.forEach(function(p) {
    s.push(p.fulfilled, p.rejected);
  });
  var o;
  if (!a) {
    var c = [vt, void 0];
    for (Array.prototype.unshift.apply(c, n), c = c.concat(s), o = Promise.resolve(t); c.length; )
      o = o.then(c.shift(), c.shift());
    return o;
  }
  for (var f = t; n.length; ) {
    var u = n.shift(), y = n.shift();
    try {
      f = u(f);
    } catch (h) {
      y(h);
      break;
    }
  }
  try {
    o = vt(f);
  } catch (h) {
    return Promise.reject(h);
  }
  for (; s.length; )
    o = o.then(s.shift(), s.shift());
  return o;
};
k.prototype.getUri = function(t) {
  return t = fe(this.defaults, t), Qn(t.url, t.params, t.paramsSerializer).replace(/^\?/, "");
};
or.forEach(["delete", "get", "head", "options"], function(t) {
  k.prototype[t] = function(r, n) {
    return this.request(fe(n || {}, {
      method: t,
      url: r,
      data: (n || {}).data
    }));
  };
});
or.forEach(["post", "put", "patch"], function(t) {
  k.prototype[t] = function(r, n, a) {
    return this.request(fe(a || {}, {
      method: t,
      url: r,
      data: n
    }));
  };
});
var ea = k, Oe, yt;
function ur() {
  if (yt)
    return Oe;
  yt = 1;
  function e(t) {
    this.message = t;
  }
  return e.prototype.toString = function() {
    return "Cancel" + (this.message ? ": " + this.message : "");
  }, e.prototype.__CANCEL__ = !0, Oe = e, Oe;
}
var Se, bt;
function ta() {
  if (bt)
    return Se;
  bt = 1;
  var e = ur();
  function t(r) {
    if (typeof r != "function")
      throw new TypeError("executor must be a function.");
    var n;
    this.promise = new Promise(function(o) {
      n = o;
    });
    var a = this;
    r(function(o) {
      a.reason || (a.reason = new e(o), n(a.reason));
    });
  }
  return t.prototype.throwIfRequested = function() {
    if (this.reason)
      throw this.reason;
  }, t.source = function() {
    var n, a = new t(function(o) {
      n = o;
    });
    return {
      token: a,
      cancel: n
    };
  }, Se = t, Se;
}
var je, wt;
function ra() {
  return wt || (wt = 1, je = function(t) {
    return function(n) {
      return t.apply(null, n);
    };
  }), je;
}
var xe, _t;
function na() {
  return _t || (_t = 1, xe = function(t) {
    return typeof t == "object" && t.isAxiosError === !0;
  }), xe;
}
var Tt = j, aa = Yt, re = ea, sa = nr, oa = ze;
function cr(e) {
  var t = new re(e), r = aa(re.prototype.request, t);
  return Tt.extend(r, re.prototype, t), Tt.extend(r, t), r;
}
var P = cr(oa);
P.Axios = re;
P.create = function(t) {
  return cr(sa(P.defaults, t));
};
P.Cancel = ur();
P.CancelToken = ta();
P.isCancel = rr();
P.all = function(t) {
  return Promise.all(t);
};
P.spread = ra();
P.isAxiosError = na();
He.exports = P;
He.exports.default = P;
var ia = He.exports, ua = ia;
const ca = /* @__PURE__ */ Jr(ua);
var fa = typeof global == "object" && global && global.Object === Object && global;
const fr = fa;
var la = typeof self == "object" && self && self.Object === Object && self, da = fr || la || Function("return this")();
const C = da;
var pa = C.Symbol;
const I = pa;
var lr = Object.prototype, ha = lr.hasOwnProperty, ga = lr.toString, z = I ? I.toStringTag : void 0;
function ma(e) {
  var t = ha.call(e, z), r = e[z];
  try {
    e[z] = void 0;
    var n = !0;
  } catch {
  }
  var a = ga.call(e);
  return n && (t ? e[z] = r : delete e[z]), a;
}
var va = Object.prototype, ya = va.toString;
function ba(e) {
  return ya.call(e);
}
var wa = "[object Null]", _a = "[object Undefined]", $t = I ? I.toStringTag : void 0;
function Z(e) {
  return e == null ? e === void 0 ? _a : wa : $t && $t in Object(e) ? ma(e) : ba(e);
}
function D(e) {
  return e != null && typeof e == "object";
}
var Ta = Array.isArray;
const W = Ta;
function Q(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var $a = "[object AsyncFunction]", Aa = "[object Function]", Ea = "[object GeneratorFunction]", Oa = "[object Proxy]";
function dr(e) {
  if (!Q(e))
    return !1;
  var t = Z(e);
  return t == Aa || t == Ea || t == $a || t == Oa;
}
var Sa = C["__core-js_shared__"];
const Pe = Sa;
var At = function() {
  var e = /[^.]+$/.exec(Pe && Pe.keys && Pe.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function ja(e) {
  return !!At && At in e;
}
var xa = Function.prototype, Pa = xa.toString;
function q(e) {
  if (e != null) {
    try {
      return Pa.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Ca = /[\\^$.*+?()[\]{}|]/g, Ra = /^\[object .+?Constructor\]$/, La = Function.prototype, Ia = Object.prototype, Ua = La.toString, Da = Ia.hasOwnProperty, Na = RegExp(
  "^" + Ua.call(Da).replace(Ca, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Ma(e) {
  if (!Q(e) || ja(e))
    return !1;
  var t = dr(e) ? Na : Ra;
  return t.test(q(e));
}
function qa(e, t) {
  return e == null ? void 0 : e[t];
}
function B(e, t) {
  var r = qa(e, t);
  return Ma(r) ? r : void 0;
}
var Ba = B(C, "WeakMap");
const Ne = Ba;
var Et = Object.create, Fa = function() {
  function e() {
  }
  return function(t) {
    if (!Q(t))
      return {};
    if (Et)
      return Et(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}();
const Ha = Fa;
function Ga(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var Ka = function() {
  try {
    var e = B(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}();
const Ot = Ka;
function za(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var Ja = 9007199254740991, Wa = /^(?:0|[1-9]\d*)$/;
function Xa(e, t) {
  var r = typeof e;
  return t = t ?? Ja, !!t && (r == "number" || r != "symbol" && Wa.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
function pr(e, t, r) {
  t == "__proto__" && Ot ? Ot(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
function We(e, t) {
  return e === t || e !== e && t !== t;
}
var Va = Object.prototype, Ya = Va.hasOwnProperty;
function hr(e, t, r) {
  var n = e[t];
  (!(Ya.call(e, t) && We(n, r)) || r === void 0 && !(t in e)) && pr(e, t, r);
}
function le(e, t, r, n) {
  var a = !r;
  r || (r = {});
  for (var s = -1, o = t.length; ++s < o; ) {
    var c = t[s], f = n ? n(r[c], e[c], c, r, e) : void 0;
    f === void 0 && (f = e[c]), a ? pr(r, c, f) : hr(r, c, f);
  }
  return r;
}
var ka = 9007199254740991;
function gr(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= ka;
}
function mr(e) {
  return e != null && gr(e.length) && !dr(e);
}
var Za = Object.prototype;
function Xe(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Za;
  return e === r;
}
function Qa(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var es = "[object Arguments]";
function St(e) {
  return D(e) && Z(e) == es;
}
var vr = Object.prototype, ts = vr.hasOwnProperty, rs = vr.propertyIsEnumerable, ns = St(function() {
  return arguments;
}()) ? St : function(e) {
  return D(e) && ts.call(e, "callee") && !rs.call(e, "callee");
};
const as = ns;
function ss() {
  return !1;
}
var yr = typeof exports == "object" && exports && !exports.nodeType && exports, jt = yr && typeof module == "object" && module && !module.nodeType && module, os = jt && jt.exports === yr, xt = os ? C.Buffer : void 0, is = xt ? xt.isBuffer : void 0, us = is || ss;
const ae = us;
var cs = "[object Arguments]", fs = "[object Array]", ls = "[object Boolean]", ds = "[object Date]", ps = "[object Error]", hs = "[object Function]", gs = "[object Map]", ms = "[object Number]", vs = "[object Object]", ys = "[object RegExp]", bs = "[object Set]", ws = "[object String]", _s = "[object WeakMap]", Ts = "[object ArrayBuffer]", $s = "[object DataView]", As = "[object Float32Array]", Es = "[object Float64Array]", Os = "[object Int8Array]", Ss = "[object Int16Array]", js = "[object Int32Array]", xs = "[object Uint8Array]", Ps = "[object Uint8ClampedArray]", Cs = "[object Uint16Array]", Rs = "[object Uint32Array]", w = {};
w[As] = w[Es] = w[Os] = w[Ss] = w[js] = w[xs] = w[Ps] = w[Cs] = w[Rs] = !0;
w[cs] = w[fs] = w[Ts] = w[ls] = w[$s] = w[ds] = w[ps] = w[hs] = w[gs] = w[ms] = w[vs] = w[ys] = w[bs] = w[ws] = w[_s] = !1;
function Ls(e) {
  return D(e) && gr(e.length) && !!w[Z(e)];
}
function Ve(e) {
  return function(t) {
    return e(t);
  };
}
var br = typeof exports == "object" && exports && !exports.nodeType && exports, J = br && typeof module == "object" && module && !module.nodeType && module, Is = J && J.exports === br, Ce = Is && fr.process, Us = function() {
  try {
    var e = J && J.require && J.require("util").types;
    return e || Ce && Ce.binding && Ce.binding("util");
  } catch {
  }
}();
const K = Us;
var Pt = K && K.isTypedArray, Ds = Pt ? Ve(Pt) : Ls;
const wr = Ds;
var Ns = Object.prototype, Ms = Ns.hasOwnProperty;
function _r(e, t) {
  var r = W(e), n = !r && as(e), a = !r && !n && ae(e), s = !r && !n && !a && wr(e), o = r || n || a || s, c = o ? Qa(e.length, String) : [], f = c.length;
  for (var u in e)
    (t || Ms.call(e, u)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    Xa(u, f))) && c.push(u);
  return c;
}
function Tr(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var qs = Tr(Object.keys, Object);
const Bs = qs;
var Fs = Object.prototype, Hs = Fs.hasOwnProperty;
function Gs(e) {
  if (!Xe(e))
    return Bs(e);
  var t = [];
  for (var r in Object(e))
    Hs.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
function Ye(e) {
  return mr(e) ? _r(e) : Gs(e);
}
function Ks(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var zs = Object.prototype, Js = zs.hasOwnProperty;
function Ws(e) {
  if (!Q(e))
    return Ks(e);
  var t = Xe(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !Js.call(e, n)) || r.push(n);
  return r;
}
function ke(e) {
  return mr(e) ? _r(e, !0) : Ws(e);
}
var Xs = B(Object, "create");
const X = Xs;
function Vs() {
  this.__data__ = X ? X(null) : {}, this.size = 0;
}
function Ys(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var ks = "__lodash_hash_undefined__", Zs = Object.prototype, Qs = Zs.hasOwnProperty;
function eo(e) {
  var t = this.__data__;
  if (X) {
    var r = t[e];
    return r === ks ? void 0 : r;
  }
  return Qs.call(t, e) ? t[e] : void 0;
}
var to = Object.prototype, ro = to.hasOwnProperty;
function no(e) {
  var t = this.__data__;
  return X ? t[e] !== void 0 : ro.call(t, e);
}
var ao = "__lodash_hash_undefined__";
function so(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = X && t === void 0 ? ao : t, this;
}
function N(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
N.prototype.clear = Vs;
N.prototype.delete = Ys;
N.prototype.get = eo;
N.prototype.has = no;
N.prototype.set = so;
function oo() {
  this.__data__ = [], this.size = 0;
}
function de(e, t) {
  for (var r = e.length; r--; )
    if (We(e[r][0], t))
      return r;
  return -1;
}
var io = Array.prototype, uo = io.splice;
function co(e) {
  var t = this.__data__, r = de(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : uo.call(t, r, 1), --this.size, !0;
}
function fo(e) {
  var t = this.__data__, r = de(t, e);
  return r < 0 ? void 0 : t[r][1];
}
function lo(e) {
  return de(this.__data__, e) > -1;
}
function po(e, t) {
  var r = this.__data__, n = de(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
function L(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
L.prototype.clear = oo;
L.prototype.delete = co;
L.prototype.get = fo;
L.prototype.has = lo;
L.prototype.set = po;
var ho = B(C, "Map");
const V = ho;
function go() {
  this.size = 0, this.__data__ = {
    hash: new N(),
    map: new (V || L)(),
    string: new N()
  };
}
function mo(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
function pe(e, t) {
  var r = e.__data__;
  return mo(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
function vo(e) {
  var t = pe(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
function yo(e) {
  return pe(this, e).get(e);
}
function bo(e) {
  return pe(this, e).has(e);
}
function wo(e, t) {
  var r = pe(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
function F(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
F.prototype.clear = go;
F.prototype.delete = vo;
F.prototype.get = yo;
F.prototype.has = bo;
F.prototype.set = wo;
function $r(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var _o = Tr(Object.getPrototypeOf, Object);
const Ar = _o;
function To() {
  this.__data__ = new L(), this.size = 0;
}
function $o(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
function Ao(e) {
  return this.__data__.get(e);
}
function Eo(e) {
  return this.__data__.has(e);
}
var Oo = 200;
function So(e, t) {
  var r = this.__data__;
  if (r instanceof L) {
    var n = r.__data__;
    if (!V || n.length < Oo - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new F(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
function R(e) {
  var t = this.__data__ = new L(e);
  this.size = t.size;
}
R.prototype.clear = To;
R.prototype.delete = $o;
R.prototype.get = Ao;
R.prototype.has = Eo;
R.prototype.set = So;
function jo(e, t) {
  return e && le(t, Ye(t), e);
}
function xo(e, t) {
  return e && le(t, ke(t), e);
}
var Er = typeof exports == "object" && exports && !exports.nodeType && exports, Ct = Er && typeof module == "object" && module && !module.nodeType && module, Po = Ct && Ct.exports === Er, Rt = Po ? C.Buffer : void 0, Lt = Rt ? Rt.allocUnsafe : void 0;
function Co(e, t) {
  if (t)
    return e.slice();
  var r = e.length, n = Lt ? Lt(r) : new e.constructor(r);
  return e.copy(n), n;
}
function Ro(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (s[a++] = o);
  }
  return s;
}
function Or() {
  return [];
}
var Lo = Object.prototype, Io = Lo.propertyIsEnumerable, It = Object.getOwnPropertySymbols, Uo = It ? function(e) {
  return e == null ? [] : (e = Object(e), Ro(It(e), function(t) {
    return Io.call(e, t);
  }));
} : Or;
const Ze = Uo;
function Do(e, t) {
  return le(e, Ze(e), t);
}
var No = Object.getOwnPropertySymbols, Mo = No ? function(e) {
  for (var t = []; e; )
    $r(t, Ze(e)), e = Ar(e);
  return t;
} : Or;
const Sr = Mo;
function qo(e, t) {
  return le(e, Sr(e), t);
}
function jr(e, t, r) {
  var n = t(e);
  return W(e) ? n : $r(n, r(e));
}
function Me(e) {
  return jr(e, Ye, Ze);
}
function Bo(e) {
  return jr(e, ke, Sr);
}
var Fo = B(C, "DataView");
const qe = Fo;
var Ho = B(C, "Promise");
const Be = Ho;
var Go = B(C, "Set");
const Fe = Go;
var Ut = "[object Map]", Ko = "[object Object]", Dt = "[object Promise]", Nt = "[object Set]", Mt = "[object WeakMap]", qt = "[object DataView]", zo = q(qe), Jo = q(V), Wo = q(Be), Xo = q(Fe), Vo = q(Ne), U = Z;
(qe && U(new qe(new ArrayBuffer(1))) != qt || V && U(new V()) != Ut || Be && U(Be.resolve()) != Dt || Fe && U(new Fe()) != Nt || Ne && U(new Ne()) != Mt) && (U = function(e) {
  var t = Z(e), r = t == Ko ? e.constructor : void 0, n = r ? q(r) : "";
  if (n)
    switch (n) {
      case zo:
        return qt;
      case Jo:
        return Ut;
      case Wo:
        return Dt;
      case Xo:
        return Nt;
      case Vo:
        return Mt;
    }
  return t;
});
const Y = U;
var Yo = Object.prototype, ko = Yo.hasOwnProperty;
function Zo(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && ko.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var Qo = C.Uint8Array;
const se = Qo;
function Qe(e) {
  var t = new e.constructor(e.byteLength);
  return new se(t).set(new se(e)), t;
}
function ei(e, t) {
  var r = t ? Qe(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var ti = /\w*$/;
function ri(e) {
  var t = new e.constructor(e.source, ti.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Bt = I ? I.prototype : void 0, Ft = Bt ? Bt.valueOf : void 0;
function ni(e) {
  return Ft ? Object(Ft.call(e)) : {};
}
function ai(e, t) {
  var r = t ? Qe(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var si = "[object Boolean]", oi = "[object Date]", ii = "[object Map]", ui = "[object Number]", ci = "[object RegExp]", fi = "[object Set]", li = "[object String]", di = "[object Symbol]", pi = "[object ArrayBuffer]", hi = "[object DataView]", gi = "[object Float32Array]", mi = "[object Float64Array]", vi = "[object Int8Array]", yi = "[object Int16Array]", bi = "[object Int32Array]", wi = "[object Uint8Array]", _i = "[object Uint8ClampedArray]", Ti = "[object Uint16Array]", $i = "[object Uint32Array]";
function Ai(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case pi:
      return Qe(e);
    case si:
    case oi:
      return new n(+e);
    case hi:
      return ei(e, r);
    case gi:
    case mi:
    case vi:
    case yi:
    case bi:
    case wi:
    case _i:
    case Ti:
    case $i:
      return ai(e, r);
    case ii:
      return new n();
    case ui:
    case li:
      return new n(e);
    case ci:
      return ri(e);
    case fi:
      return new n();
    case di:
      return ni(e);
  }
}
function Ei(e) {
  return typeof e.constructor == "function" && !Xe(e) ? Ha(Ar(e)) : {};
}
var Oi = "[object Map]";
function Si(e) {
  return D(e) && Y(e) == Oi;
}
var Ht = K && K.isMap, ji = Ht ? Ve(Ht) : Si;
const xi = ji;
var Pi = "[object Set]";
function Ci(e) {
  return D(e) && Y(e) == Pi;
}
var Gt = K && K.isSet, Ri = Gt ? Ve(Gt) : Ci;
const Li = Ri;
var Ii = 1, Ui = 2, Di = 4, xr = "[object Arguments]", Ni = "[object Array]", Mi = "[object Boolean]", qi = "[object Date]", Bi = "[object Error]", Pr = "[object Function]", Fi = "[object GeneratorFunction]", Hi = "[object Map]", Gi = "[object Number]", Cr = "[object Object]", Ki = "[object RegExp]", zi = "[object Set]", Ji = "[object String]", Wi = "[object Symbol]", Xi = "[object WeakMap]", Vi = "[object ArrayBuffer]", Yi = "[object DataView]", ki = "[object Float32Array]", Zi = "[object Float64Array]", Qi = "[object Int8Array]", eu = "[object Int16Array]", tu = "[object Int32Array]", ru = "[object Uint8Array]", nu = "[object Uint8ClampedArray]", au = "[object Uint16Array]", su = "[object Uint32Array]", b = {};
b[xr] = b[Ni] = b[Vi] = b[Yi] = b[Mi] = b[qi] = b[ki] = b[Zi] = b[Qi] = b[eu] = b[tu] = b[Hi] = b[Gi] = b[Cr] = b[Ki] = b[zi] = b[Ji] = b[Wi] = b[ru] = b[nu] = b[au] = b[su] = !0;
b[Bi] = b[Pr] = b[Xi] = !1;
function ne(e, t, r, n, a, s) {
  var o, c = t & Ii, f = t & Ui, u = t & Di;
  if (r && (o = a ? r(e, n, a, s) : r(e)), o !== void 0)
    return o;
  if (!Q(e))
    return e;
  var y = W(e);
  if (y) {
    if (o = Zo(e), !c)
      return Ga(e, o);
  } else {
    var h = Y(e), p = h == Pr || h == Fi;
    if (ae(e))
      return Co(e, c);
    if (h == Cr || h == xr || p && !a) {
      if (o = f || p ? {} : Ei(e), !c)
        return f ? qo(e, xo(o, e)) : Do(e, jo(o, e));
    } else {
      if (!b[h])
        return a ? e : {};
      o = Ai(e, h, c);
    }
  }
  s || (s = new R());
  var d = s.get(e);
  if (d)
    return d;
  s.set(e, o), Li(e) ? e.forEach(function(i) {
    o.add(ne(i, t, r, i, e, s));
  }) : xi(e) && e.forEach(function(i, m) {
    o.set(m, ne(i, t, r, m, e, s));
  });
  var g = u ? f ? Bo : Me : f ? ke : Ye, l = y ? void 0 : g(e);
  return za(l || e, function(i, m) {
    l && (m = i, i = e[m]), hr(o, m, ne(i, t, r, m, e, s));
  }), o;
}
var ou = 1, iu = 4;
function Re(e) {
  return ne(e, ou | iu);
}
var uu = "__lodash_hash_undefined__";
function cu(e) {
  return this.__data__.set(e, uu), this;
}
function fu(e) {
  return this.__data__.has(e);
}
function oe(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new F(); ++t < r; )
    this.add(e[t]);
}
oe.prototype.add = oe.prototype.push = cu;
oe.prototype.has = fu;
function lu(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
function du(e, t) {
  return e.has(t);
}
var pu = 1, hu = 2;
function Rr(e, t, r, n, a, s) {
  var o = r & pu, c = e.length, f = t.length;
  if (c != f && !(o && f > c))
    return !1;
  var u = s.get(e), y = s.get(t);
  if (u && y)
    return u == t && y == e;
  var h = -1, p = !0, d = r & hu ? new oe() : void 0;
  for (s.set(e, t), s.set(t, e); ++h < c; ) {
    var g = e[h], l = t[h];
    if (n)
      var i = o ? n(l, g, h, t, e, s) : n(g, l, h, e, t, s);
    if (i !== void 0) {
      if (i)
        continue;
      p = !1;
      break;
    }
    if (d) {
      if (!lu(t, function(m, T) {
        if (!du(d, T) && (g === m || a(g, m, r, n, s)))
          return d.push(T);
      })) {
        p = !1;
        break;
      }
    } else if (!(g === l || a(g, l, r, n, s))) {
      p = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), p;
}
function gu(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
function mu(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var vu = 1, yu = 2, bu = "[object Boolean]", wu = "[object Date]", _u = "[object Error]", Tu = "[object Map]", $u = "[object Number]", Au = "[object RegExp]", Eu = "[object Set]", Ou = "[object String]", Su = "[object Symbol]", ju = "[object ArrayBuffer]", xu = "[object DataView]", Kt = I ? I.prototype : void 0, Le = Kt ? Kt.valueOf : void 0;
function Pu(e, t, r, n, a, s, o) {
  switch (r) {
    case xu:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case ju:
      return !(e.byteLength != t.byteLength || !s(new se(e), new se(t)));
    case bu:
    case wu:
    case $u:
      return We(+e, +t);
    case _u:
      return e.name == t.name && e.message == t.message;
    case Au:
    case Ou:
      return e == t + "";
    case Tu:
      var c = gu;
    case Eu:
      var f = n & vu;
      if (c || (c = mu), e.size != t.size && !f)
        return !1;
      var u = o.get(e);
      if (u)
        return u == t;
      n |= yu, o.set(e, t);
      var y = Rr(c(e), c(t), n, a, s, o);
      return o.delete(e), y;
    case Su:
      if (Le)
        return Le.call(e) == Le.call(t);
  }
  return !1;
}
var Cu = 1, Ru = Object.prototype, Lu = Ru.hasOwnProperty;
function Iu(e, t, r, n, a, s) {
  var o = r & Cu, c = Me(e), f = c.length, u = Me(t), y = u.length;
  if (f != y && !o)
    return !1;
  for (var h = f; h--; ) {
    var p = c[h];
    if (!(o ? p in t : Lu.call(t, p)))
      return !1;
  }
  var d = s.get(e), g = s.get(t);
  if (d && g)
    return d == t && g == e;
  var l = !0;
  s.set(e, t), s.set(t, e);
  for (var i = o; ++h < f; ) {
    p = c[h];
    var m = e[p], T = t[p];
    if (n)
      var x = o ? n(T, m, p, t, e, s) : n(m, T, p, e, t, s);
    if (!(x === void 0 ? m === T || a(m, T, r, n, s) : x)) {
      l = !1;
      break;
    }
    i || (i = p == "constructor");
  }
  if (l && !i) {
    var O = e.constructor, _ = t.constructor;
    O != _ && "constructor" in e && "constructor" in t && !(typeof O == "function" && O instanceof O && typeof _ == "function" && _ instanceof _) && (l = !1);
  }
  return s.delete(e), s.delete(t), l;
}
var Uu = 1, zt = "[object Arguments]", Jt = "[object Array]", ee = "[object Object]", Du = Object.prototype, Wt = Du.hasOwnProperty;
function Nu(e, t, r, n, a, s) {
  var o = W(e), c = W(t), f = o ? Jt : Y(e), u = c ? Jt : Y(t);
  f = f == zt ? ee : f, u = u == zt ? ee : u;
  var y = f == ee, h = u == ee, p = f == u;
  if (p && ae(e)) {
    if (!ae(t))
      return !1;
    o = !0, y = !1;
  }
  if (p && !y)
    return s || (s = new R()), o || wr(e) ? Rr(e, t, r, n, a, s) : Pu(e, t, f, r, n, a, s);
  if (!(r & Uu)) {
    var d = y && Wt.call(e, "__wrapped__"), g = h && Wt.call(t, "__wrapped__");
    if (d || g) {
      var l = d ? e.value() : e, i = g ? t.value() : t;
      return s || (s = new R()), a(l, i, r, n, s);
    }
  }
  return p ? (s || (s = new R()), Iu(e, t, r, n, a, s)) : !1;
}
function Lr(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !D(e) && !D(t) ? e !== e && t !== t : Nu(e, t, r, n, Lr, a);
}
function Mu(e, t) {
  return Lr(e, t);
}
function Ie(e) {
  return typeof e == "function" ? e() : Fr(e);
}
const qu = typeof window < "u", Bu = () => {
};
function Fu(e, t = !0) {
  Vt() ? Mr(e) : t ? e() : qr(e);
}
function Hu(e) {
  Vt() && Br(e);
}
const Gu = qu ? window.document : void 0;
function Ku(e, t = Bu, r = {}) {
  const {
    immediate: n = !0,
    manual: a = !1,
    type: s = "text/javascript",
    async: o = !0,
    crossOrigin: c,
    referrerPolicy: f,
    noModule: u,
    defer: y,
    document: h = Gu,
    attrs: p = {}
  } = r, d = Hr(null);
  let g = null;
  const l = (T) => new Promise((x, O) => {
    const _ = ($) => (d.value = $, x($), $);
    if (!h) {
      x(!1);
      return;
    }
    let S = !1, v = h.querySelector(`script[src="${Ie(e)}"]`);
    v ? v.hasAttribute("data-loaded") && _(v) : (v = h.createElement("script"), v.type = s, v.async = o, v.src = Ie(e), y && (v.defer = y), c && (v.crossOrigin = c), u && (v.noModule = u), f && (v.referrerPolicy = f), Object.entries(p).forEach(([$, Ir]) => v == null ? void 0 : v.setAttribute($, Ir)), S = !0), v.addEventListener("error", ($) => O($)), v.addEventListener("abort", ($) => O($)), v.addEventListener("load", () => {
      v.setAttribute("data-loaded", "true"), t(v), _(v);
    }), S && (v = h.head.appendChild(v)), T || _(v);
  }), i = (T = !0) => (g || (g = l(T)), g), m = () => {
    if (!h)
      return;
    g = null, d.value && (d.value = null);
    const T = h.querySelector(`script[src="${Ie(e)}"]`);
    T && h.head.removeChild(T);
  };
  return n && !a && Fu(i), a || Hu(m), { scriptTag: d, load: i, unload: m };
}
const zu = () => new Promise((e, t) => {
  var a, s;
  if (typeof window > "u" || !window.grecaptcha)
    return t("Germinal: Recaptcha not found on window");
  const n = (s = ((a = ie().props) == null ? void 0 : a.modules).cf7) == null ? void 0 : s.recaptchaSiteKey;
  if (!n)
    return t("Germinal: Not Recaptcha site key set in CF7");
  window.grecaptcha.ready(function() {
    var o;
    (o = window.grecaptcha) == null || o.execute(n, { action: "submit" }).then(function(c) {
      e(c);
    });
  });
}), ku = (e = {}, t = {}) => {
  var p, d, g;
  const { immediate: r = !1, redirect: n, formId: a = "contact" } = t;
  let s = Re(e), o, c = (l) => l;
  const f = (p = ie().props) == null ? void 0 : p.modules;
  (d = f == null ? void 0 : f.cf7) != null && d.recaptchaUrl || console.warn("Germinal: No recaptcha URL available");
  const { load: u, unload: y } = (g = f == null ? void 0 : f.cf7) != null && g.recaptchaUrl ? Ku(
    f.cf7.recaptchaUrl,
    // on script tag loaded.
    void 0,
    {
      manual: r === !1
    }
  ) : { load: () => {
  }, unload: () => {
  } }, h = Gr({
    ...e,
    errors: {},
    formMessage: "",
    isDirty: !1,
    hasErrors: !1,
    processing: !1,
    progress: null,
    wasSuccessful: !1,
    recentlySuccessful: !1,
    loadRecaptcha: u,
    unloadRecaptcha: () => {
      document.querySelectorAll(".grecaptcha-badge").forEach((i) => {
        i != null && i.parentElement && i.parentElement.remove();
      }), window.grecaptcha && delete window.grecaptcha, window.___grecaptcha_cfg && delete window.___grecaptcha_cfg, y();
    },
    data() {
      return Object.keys(e).reduce((l, i) => (l[i] = this[i], l), {});
    },
    transform(l) {
      return c = l, this;
    },
    defaults(l, i) {
      return typeof l > "u" ? s = this.data() : s = Object.assign(
        {},
        Re(s),
        typeof l == "string" ? { [l]: i } : l
      ), this;
    },
    reset(...l) {
      let i = Re(s);
      return l.length === 0 ? Object.assign(this, i) : Object.assign(
        this,
        Object.keys(i).filter((m) => l.includes(m)).reduce((m, T) => (m[T] = i[T], m), {})
      ), this;
    },
    setError(l, i) {
      return Object.assign(
        this.errors,
        typeof l == "string" ? { [l]: i } : l
      ), this.hasErrors = Object.keys(this.errors).length > 0, this;
    },
    clearErrors(...l) {
      return this.errors = Object.keys(this.errors).reduce(
        (i, m) => ({
          ...i,
          ...l.length > 0 && !l.includes(m) ? { [m]: this.errors[m] } : {}
        }),
        {}
      ), this.hasErrors = Object.keys(this.errors).length > 0, this;
    },
    async submit(l = "post", i, { headers: m = {}, ...T } = {}) {
      const x = c(this.data());
      this.processing = !0, this.wasSuccessful = !1, this.recentlySuccessful = !1, o && clearTimeout(o);
      const O = new FormData();
      return O.append("_wpcf7_recaptcha_response", await zu()), O.append("_wpcf7_unit_tag", `wpcf7-f-${a}-123`), Object.keys(x).forEach((_) => {
        O.append(_, x[_]);
      }), ca({
        method: l,
        url: i,
        data: O,
        headers: m,
        ...T
      }).then((_) => {
        if (_.data.message && (this.formMessage = _.data.message), _.data.status === "validation_failed") {
          const S = _.data.invalid_fields.reduce(
            (v, $) => (v[$.field] = $.message, v),
            {}
          );
          this.clearErrors().setError(S), this.formMessage = "There were errors with parts of this form";
        } else if (_.data.status === "spam") {
          const S = { recaptcha: "failed" };
          this.setError(S), this.formMessage = "There was a problem submitting this form";
        } else
          this.clearErrors(), this.wasSuccessful = !0, this.recentlySuccessful = !0, o = window.setTimeout(() => this.recentlySuccessful = !1, 2e3), this.reset(), this.isDirty = !1, n && zr.get(n);
      }).catch((_) => {
        console.log(_);
      }).finally(() => {
        this.processing = !1, this.progress = null;
      });
    },
    get(l, i) {
      return this.submit("get", l, i);
    },
    post(l, i) {
      return this.submit("post", l, i);
    },
    patch(l, i) {
      return this.submit("patch", l, i);
    },
    put(l, i) {
      return this.submit("put", l, i);
    },
    delete(l, i) {
      return this.submit("delete", l, i);
    }
  });
  return Kr(
    h,
    () => {
      h.isDirty = !Mu(h.data(), s);
    },
    { immediate: !0, deep: !0 }
  ), h;
}, Zu = (e) => Xt(() => {
  var a;
  const t = Array.isArray(e) ? e : [e], r = (a = ie().props) == null ? void 0 : a.sitePreferences, n = (s) => {
    var o;
    return ((o = r == null ? void 0 : r.find((c) => c.slug === s)) == null ? void 0 : o.value) || null;
  };
  return t.reduce((s, o) => (s[o] = n(o), s), {});
}), Ju = (e) => {
  const [t, r] = e.split("?"), n = {};
  return r && r.split("&").forEach((a) => {
    const [s, o] = a.split("=");
    n[s] = o;
  }), {
    pageName: t,
    params: n
  };
};
async function Qu(e) {
  const {
    name: t,
    pages: r,
    templates: n,
    pageRoot: a = "./pages",
    pageExtension: s = "vue",
    templateRoot: o = "./templates",
    defaultTemplate: c = "Default",
    templateExtension: f = "vue",
    templateWrap: u
  } = e, { pageName: y, params: h } = Ju(t), p = `${a}/${y}.${s}`, d = r[p];
  if (!d)
    throw new Error(`Unable to find page ${a}/${t}.${s}`);
  const g = typeof d == "function" ? await d() : await d;
  if (h.template) {
    const l = `${o}/${h.template}.${f}`, i = n[l], m = typeof i == "function" ? await i() : await i;
    g.default.layout = u ? [u, m.default] : m.default;
  } else if (g.default.layout) {
    if (u) {
      const l = Array.isArray(g.default.layout) ? g.default.layout.filter((i) => i.__name !== "TemplateWrap") : [g.default.layout];
      g.default.layout = [u, ...l];
    }
  } else {
    const l = `${o}/${c}.${f}`, i = n[l], m = typeof i == "function" ? await i() : await i;
    g.default.layout = u ? [u, m.default] : m.default;
  }
  if (typeof g > "u")
    throw new Error(`Page not found: ${p}`);
  return g;
}
export {
  Vu as Test,
  Qu as resolvePageComponent,
  Yu as useAcf,
  ku as useCf7Form,
  Zu as useSitePreferences
};
