<template>
	<div class="w-full flex justify-center">
		<h1
			ref="headerRef"
			class="home-headline text-5xl text-center font-light flex flex-col"
			:class="{
				'is-mounted': isMounted,
			}"
		>
			<MainHeading />

			<transition name="cycle-text" mode="out-in" :duration="600">
				<DedicationHeading :key="currentDedication" />
			</transition>
			<LastHeading />
		</h1>
	</div>
</template>

<script setup>
import { onMounted, nextTick } from "vue";
import { useSplitting } from "vue3-splitting";

let isMounted = ref(false);
const dedications = ["Business", "Website", "Branding", "Store", "Applications", "Marketing"];

let currentDedication = ref(0);
const currentDedicationText = computed(() => dedications[currentDedication.value]);

const { Splitting: MainHeading, counts: mainCounts } = useSplitting("Let's Take Your", {
	wrapperTag: "span",
	wrapperClass: "transition-all",
	lineTag: "span",
	lineClass: "overflow-hidden inline-block text-3xl md:text-4xl",
	words: false,
});

const dedicationConfig = computed(() => ({
	wrapperTag: "span",
	wrapperClass: "whitespace-nowrap",
	lineTag: "span",
	lineClass: "overflow-hidden inline-block font-medium text-6xl md:text-8xl !leading-[1.18] -mt-2",
	charOffset: mainCounts.value.chars,
	words: false,
}));

const { Splitting: DedicationHeading, counts: dedicationCounts } = useSplitting(
	currentDedicationText,
	dedicationConfig
);

const lastConfig = computed(() => ({
	wrapperClass: "transition-all delay-300",
	wrapperTag: "span",
	charOffset: mainCounts.value.chars + dedicationCounts.value.chars,
	lineTag: "span",
	lineClass: "overflow-hidden inline-block pb-2 text-3xl md:text-4xl font-light",
	words: false,
}));

const { Splitting: LastHeading } = useSplitting("Further Together.", lastConfig);

onMounted(() => {
	nextTick(() => (isMounted.value = true));
	setInterval(() => {
		currentDedication.value++;
		if (currentDedication.value > dedications.length - 1) currentDedication.value = 0;
	}, 4000);
});
</script>

<style lang="postcss">
.home-headline {
	.v3sp-w {
		overflow: hidden;
	}
	.v3sp-c {
		transition-property: transform;
		transition-duration: 600ms;
		transition-delay: calc(0.02s * (var(--char-index) + var(--main-char-total, 0)));
		transform: translateY(100%);
	}

	&.is-mounted {
		.v3sp-c {
			transform: translateY(0%);
		}
	}

	.cycle-text-enter-active .v3sp-c,
	.cycle-text-leave-active .v3sp-c {
		transition-property: transform;
		transition-duration: 600ms;
		transition-delay: calc(0.02s * var(--char-index));
	}
	.cycle-text-enter-from .v3sp-c {
		transform: translateY(100%);
	}
	.cycle-text-leave-to .v3sp-c {
		transform: translateY(-100%);
	}
}
</style>
